<template>
  <div id="editDialog">
    <el-dialog
      :title="userId ? '编辑用户' : '新增用户'"
      :visible.sync="show"
      :close-on-press-escape="false"
      @open="open"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="addForm.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!userId">
          <el-input
            type="password"
            v-model="addForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" v-else>
          <el-input
            type="password"
            v-model="editPaw"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作权限" prop="roleId">
          <el-select v-model="addForm.roleId" placeholder="请选择操作权限">
            <el-option
              :label="item.roleName"
              :value="item.roleId"
              v-for="item of authorityList"
              :key="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否管理员" prop="isAdmin">
          <el-radio-group v-model="addForm.isAdmin" @change="isAdminChange">
            <el-radio label="否">否</el-radio>
            <el-radio label="是">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="停车场" v-if="addForm.isAdmin === '否'">
          <el-select
            v-model="addForm.parkingLotIds"
            multiple
            placeholder="请选择停车场"
            @change="parkingLotChange"
          >
            <el-option
              :label="item.parkingLotName"
              :value="item.parkingLotId"
              v-for="item of parkingLotList"
              :key="item.parkingLotId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订阅停车场">
          <el-select
            v-model="addForm.parkingSubIds"
            multiple
            placeholder="请选择订阅停车场"
          >
            <el-option
              :label="item.parkingLotName"
              :value="item.parkingLotId"
              v-for="item of parkingSubList"
              :key="item.parkingLotId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            v-model="addForm.contactName"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            v-model="addForm.contactPhone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      authorityList: [],
      parkingSubList: [],
      userId: "",
      editPaw: "",
      addForm: {
        username: "",
        password: "",
        roleId: "",
        isAdmin: "否",
        parkingLotIds: [],
        parkingSubIds: [],
        contactName: "",
        contactPhone: "",
      },
      addFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        roleId: [
          { required: true, message: "请选择操作权限", trigger: "change" },
        ],
        isAdmin: [
          { required: true, message: "请选择是否管理员", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  created() {
    this.getAuthorityList();
  },
  methods: {
    open() {
      if (this.addForm.isAdmin === "否") {
        this.parkingSubList = this.parkingLotList.filter((item) => {
          return this.addForm.parkingLotIds.includes(item.parkingLotId);
        });
      } else {
        this.parkingSubList = this.parkingLotList;
      }
    },
    isAdminChange(value) {
      if (value === "是") {
        this.parkingSubList = this.parkingLotList;
      } else {
        this.parkingSubList = this.parkingLotList.filter((item) => {
          return this.addForm.parkingLotIds.includes(item.parkingLotId);
        });
        this.$set(this.addForm, "parkingSubIds", []);
      }
    },
    parkingLotChange() {
      if (this.addForm.isAdmin === "否") {
        this.$set(this.addForm, "parkingSubIds", []);
        this.parkingSubList = this.parkingLotList.filter((item) => {
          return this.addForm.parkingLotIds.includes(item.parkingLotId);
        });
      }
    },
    reset() {
      this.userId = "";
      this.$set(this, "addForm", {
        username: "",
        password: "",
        roleId: "",
        isAdmin: "否",
        parkingLotIds: [],
        parkingSubIds: [],
        contactName: "",
        contactPhone: "",
      });
      this.$refs["addForm"].resetFields();
    },
    async getAuthorityList() {
      try {
        let res = await this.$http.get("/role/list/all");
        if (res.code === 0) {
          this.authorityList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      let url = "/web/user/add";
      if (this.userId) {
        url = "/web/user/edit";
        if (this.editPaw) {
          this.addForm.password = this.editPaw;
        }
        this.addForm.userId = this.userId;
      }
      try {
        this.loading = true;
        let res = await this.$http.post(url, {
          ...this.addForm,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#editDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;

      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select {
          width: 100%;
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
