<template>
  <div id="systemUserList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统用户</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addUser"
              type="primary"
              v-if="buttonList.includes('systemUserList-addSystemUser')"
              @click="$refs.editDialog.show = true"
              ><i class="iconfont icon-jiahao"></i> 新增用户
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="username" label="用户姓名" align="center">
        </el-table-column>
        <el-table-column prop="contactName" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="contactPhone" label="用户手机号" align="center">
        </el-table-column>
        <el-table-column prop="userRole" label="类型" align="center">
        </el-table-column>
        <el-table-column prop="roleName" label="权限管理" align="center">
        </el-table-column>
        <el-table-column prop="parkingLotNames" label="管理车场" align="center">
        </el-table-column>
        <el-table-column
          prop="parkingSubNames"
          label="订阅停车场"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="isAdmin" label="是否管理员" align="center">
        </el-table-column>
        <el-table-column prop="accountFreeze" label="账号冻结" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.accountFreeze"
              active-color="#ff4949"
              inactive-color="#C0CCDA"
              @change="accountFreezeChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="buttonList.includes('systemUserList-edit')"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="del(scope.row.userId)"
              v-if="buttonList.includes('systemUserList-delete')"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <edit-dialog ref="editDialog" :getList="getList" />
  </div>
</template>
<script>
import editDialog from "./editDialog.vue";

export default {
  components: {
    editDialog,
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate = {
        keyword: "",
      };
      this.getList(1);
    },
    edit(row) {
      this.$refs.editDialog.userId = row.userId;
      let parkingLotIds = row.parkingLotList.map((item) => item.parkingLotId);
      let parkingSubIds = row.parkingTalkList.map((item) => item.parkingLotId);

      Object.assign(this.$refs.editDialog.addForm, {
        username: row.username,
        password: row.password,
        localIpAddr: row.localIpAddr,
        rtspPort: row.rtspPort,
        roleId: row.roleId,
        isAdmin: row.isAdmin,
        parkingLotIds,
        parkingSubIds,
        contactName: row.contactName,
        contactPhone: row.contactPhone,
      });
      this.$refs.editDialog.show = true;
    },
    del(userId) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/web/user/delete/${userId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/web/user/list", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.parkingLotNames = item.parkingLotList
              .map((item) => item.parkingLotName)
              .join("、");
            item.parkingSubNames = item.parkingTalkList
              .map((item) => item.parkingLotName)
              .join("、");
            item.accountFreeze = item.accountFreeze === "是" ? true : false;
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    async accountFreezeChange(row) {
      try {
        let res = await this.$http.post("/web/user/edit", {
          userId: row.userId,
          accountFreeze: row.accountFreeze ? "是" : "否",
          username: row.username,
          roleId: row.roleId,
          isAdmin: row.isAdmin,
          parkingLotIds: row.parkingLotList.map((item) => item.parkingLotId),
          parkingSubIds: row.parkingTalkList.map((item) => item.parkingLotId),
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.msg);
          this.getList();
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>
<style lang="scss">
#systemUserList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addUser {
            background-color: #ff9747;
            border-color: #ff9747;

            i {
              font-size: 14px;
            }
          }
        }
      }
    }

    .el-table {
      .el-table__body-wrapper {
        .el-table__body {
          tbody {
            .el-table__row {
              .cell {
                .el-image {
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
